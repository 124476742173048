import React from 'react'
import {
  Page,
  Services,
} from '@components'

const metaData = {
  title: 'services.seo.title',
  description: 'services.seo.description'
}

const media = (props) => {
  const { locale } = props.pageContext
  return (
    <Page 
      locale={locale}
      meta={metaData}
    >
      <Services />
    </Page>
  )
}

export default media